import React from 'react'
import Blocks from './Blocks'
// eslint-disable-next-line node/no-extraneous-import
import { gql, useQuery } from '@apollo/client'
import SkeletonBlocks from '../SkeletonBlocks/Skeleton'

const GET_POSTS = gql`
  query ($categorySlug: String, $categoryArray: [String]) {
    nationalPosts(category: $categorySlug, first: 10) {
      id
      title
      uri
      linkType
      primaryCategory
      primaryCategoryURI
      spotlightDesktopImage
      spotlightMobileImage
      homepagePreviewImage
    }
    blockSettings: categories(where: { slug: $categoryArray }, first: 1) {
      nodes {
        alignment
        backgroundColour
        position
        spotlightText
        sponsoredBy
        sponsoredLogo
        prefix
        name
        databaseId
      }
    }
  }
`

interface BlockWrapperProps {
  slug: string
  title: string
  index: number
  awardsBlock?: boolean
  mobileAdBlock?: string
}

const BlockWrapper = ({
  slug,
  title,
  index,
  awardsBlock,
  mobileAdBlock
}: BlockWrapperProps) => {
  const { data, loading } = useQuery(GET_POSTS, {
    variables: { categorySlug: slug, categoryArray: [slug] }
  })

  if (loading)
    return (
      <SkeletonBlocks
        blocksNumber={12}
        order={4 * index}
        addPadding
        layoutSpacing
      />
    )

  if (data && data.nationalPosts.length === 0) return null
  let blockPosts = data.nationalPosts

  console.log('title', title)
  console.log(blockPosts)
  if (awardsBlock) blockPosts = blockPosts.slice(0, 4)

  const hasSpotlight = Boolean(
    slug !== 'TRENDING' && slug !== 'TRENDING - NATIONAL'
  )

  return (
    <Blocks
      title={title}
      posts={blockPosts}
      hasSpotlight={!awardsBlock && hasSpotlight}
      blockSlug={slug}
      settings={data?.blockSettings.nodes[0]}
      leaderboard={mobileAdBlock}
    />
  )
}

export default BlockWrapper
