import React from 'react'
import * as BreadcrumbStyles from './Breadcrumb.module.scss'
import { Link } from 'gatsby'
import { Category } from '../../../types'

export interface IPrimaryCategory {
  title: string | null
  URI: string | null
}

interface BreadcrumbProps {
  categories?: Array<Category | null>
  postType?: string
  postTitle?: string
  postURI?: string
  primaryCategory?: IPrimaryCategory
}

const Breadcrumb = ({
  categories,
  postType,
  postTitle,
  postURI,
  primaryCategory
}: BreadcrumbProps) => {
  return (
    <div className={BreadcrumbStyles.Base}>
      {postType === 'littleBlackBook' ? (
        <Link to={`/little-black-book/`}>Little Black Book</Link>
      ) : (
        <Link to={`/`}>Home</Link>
      )}
      {postType === 'awards' && <Link to={`/awards/`}>Muddy Awards</Link>}
      {primaryCategory && primaryCategory.title ? (
        <Link to={primaryCategory.URI || ''} dangerouslySetInnerHTML={{__html: primaryCategory.title}}></Link>
      ) : (
        categories &&
        categories
          .filter(category => !category?.parentId)
          .slice(0, 1)
          .map(category => (
            <Link to={category?.uri ?? ''} key={category?.id}>
              {category?.name}
            </Link>
          ))
      )}
      {postType !== 'littleBlackBook' && (
        <Link to={postURI ?? ''}>{postTitle}</Link>
      )}
    </div>
  )
}

export default Breadcrumb
