import React, { useEffect, useState } from 'react'
import { graphql, Link } from 'gatsby'
import {
  AdStarBlock,
  Category,
  GeneralSettings,
  SponsoredCategories
} from '../../types'
import { IMenu } from '../../graphql/entities/Menu'
import TopBanner from '../../components/Banners/TopBanner/TopBanner'
import Content from '../../components/Content/Content'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'
import AwardsHeader from '../../components/MuddyAwards/AwardsHeader'
import AwardsMenu from '../../components/MuddyAwards/AwardsMenu'
import Breadcrumb from '../../components/organisms/breadcrumb/Breadcrumb'
import PageMeta from '../../components/PageMeta/PageMeta'
import BlocksWrappers from '../../components/Blocks/BlocksWrappers'
import { AWARDS_COUNTY_INFO } from '../../lib/data/Awards'
import Leaderboard from '../../components/Banners/Leaderboard/Leaderboard'

export const query = graphql`
  query {
    wpgraphql {
      generalSettings {
        title
        siteId
        egSettingTwitter
        url
      }
      sponsoredCategories {
        slug
        title
      }
      adStarCodes {
        id
        adslot
      }
      awardsMenu: menu(id: "Awards", idType: NAME) {
        menuItems {
          nodes {
            id
            path
            label
          }
        }
      }
      category(id: "muddy-awards", idType: SLUG) {
        sponsoredBy
        sponsoredLogo
      }
    }
  }
`

interface AwardsFAQProps {
  data: {
    wpgraphql: {
      generalSettings: GeneralSettings
      adStarCodes: AdStarBlock[]
      awardsMenu: {
        menuItems: {
          nodes: IMenu[]
        }
      }
      category: Category
      sponsoredCategories: SponsoredCategories[]
    }
  }
}

const AwardsAboutUs = ({
  data: {
    wpgraphql: {
      generalSettings,
      adStarCodes,
      awardsMenu: {
        menuItems: { nodes: subMenu }
      },
      category,
      sponsoredCategories
    }
  }
}: AwardsFAQProps) => {
  const [trendingCat, setTrendingCat] = useState<
    SponsoredCategories | undefined
  >()

  useEffect(() => {
    const trending = sponsoredCategories.filter(c => c.slug === 'SPOTLIGHT')

    trending.length > 0 && setTrendingCat(trending[0])
  }, [sponsoredCategories])

  const countyInfo = generalSettings.siteId
    ? AWARDS_COUNTY_INFO.filter(c => c.siteId === generalSettings.siteId)[0]
    : AWARDS_COUNTY_INFO[0]

  return (
    <>
      <PageMeta
        pageData={{
          ...generalSettings,
          title: 'Muddy Stilettos Awards 2024',
          description:
            'The Muddy Stilettos Awards are the most coveted awards for independent lifestyle businesses across 28 counties, and totally FREE to enter!',
          image:
            'https://muddystilettos.co.uk/images/awards/Awards_Badge_2024.gif'
        }}
      />
      <Header />
      <TopBanner ads={adStarCodes} pageType={`awards`} />
      <Leaderboard adSlot={'TopLB'} />
      {category && (
        <AwardsHeader category={category} generalSettings={generalSettings} />
      )}
      {subMenu && (
        <AwardsMenu subMenu={subMenu} generalSettings={generalSettings} />
      )}
      <div>
        <Content>
          <Breadcrumb
            postType={`awards`}
            postTitle={'/ About Us'}
            postURI={'/awards/about-us'}
          />
          <h1 dangerouslySetInnerHTML={{ __html: 'About Us' }} />
          <p />
          <div style={{ textAlign: 'center' }}>
            <img
              style={{ margin: '0 auto', maxWidth: '100%', width: '100%' }}
              src={
                'https://dev.muddystilettos.co.uk/wp-content/uploads/2023/04/Girl-Cow-original.jpg'
              }
              alt={'Cow & Girl'}
            />
          </div>
          <p>
            So, you know about the Muddy Stilettos Awards - but have you checked
            out our epic,{' '}
            <a href="https://muddystilettos.co.uk">
              award-winning lifestyle site?
            </a>
          </p>
          <p>
            Now in our 11th year, we’re the UK’s only luxury lifestyle website
            that talks directly to over 3.8 million cosmopolitan, fun-loving,
            urbane readers each month, helping them to get more from where they
            live. Curated across 28 counties by national journalists who live
            locally, our genuine insider knowledge helps readers find the most
            exciting things do in their local area and beyond. Regularly
            featured in the national newspapers and glossies, and named as one
            of the Top 20 Next Gen media brands, we help our legions of readers
            followers
            {countyInfo?.county
              ? ` - including those in ${countyInfo.county} -`
              : ``}{' '}
            to make the most of their precious free time with our expert insider
            info on the best local pubs, boutique hotels, new openings,
            family-friendly days and under-the-radar events.
          </p>
          <p>
            Independence, careful curation, in-the-know info and an irreverent
            sense of humour sets Muddy Stilettos apart from the competition, so
            sign up to{' '}
            <Link to="/sign-up">Muddy Stilettos {countyInfo?.county}</Link> for
            the inside line on the best things happening near you – because
            life’s too short for a rubbish night out!
          </p>
          {generalSettings.siteId !== 1 && (
            <p>
              If you’d like to work with Muddy Stilettos, please get in touch
              with {countyInfo?.county}{' '}
              {countyInfo?.commercialManagerJobTitle ?? 'Commercial Manager'}{' '}
              {countyInfo?.commercialManagerName ?? 'Jo Trivedi'} at{' '}
              <a
                href={`mailto:${
                  countyInfo?.commercialManagerEmail ??
                  'joanne.trivedi@muddystilettos.co.uk'
                }`}
              >
                {countyInfo?.commercialManagerEmail ??
                  'joanne.trivedi@muddystilettos.co.uk'}
              </a>
              .
            </p>
          )}

          <p>
            <em>
              For national enquiries, please contact Head of Brand Partnerships
              Jo Trivedi at 
              <a href="mailto:joanne.trivedi@muddystilettos.co.uk">
                joanne.trivedi@muddystilettos.co.uk
              </a>
              .
            </em>
          </p>
        </Content>
      </div>
      <div>
        {trendingCat && (
          <BlocksWrappers
            slug={trendingCat?.slug || ''}
            title={trendingCat?.title || ''}
            index={1}
            awardsBlock={true}
          />
        )}
      </div>
      <Leaderboard adSlot={'BottomLB'} />
      <Footer />
    </>
  )
}

export default AwardsAboutUs
