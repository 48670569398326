import React from 'react'
import * as SkeletonStyles from './SkeletonBlocks.module.scss'

interface SkeletonBlocksProps {
  blocksNumber: number
  order: number
  addPadding?: boolean
  layoutSpacing?: boolean
}

const SkeletonBlocks = ({
  blocksNumber,
  order,
  addPadding,
  layoutSpacing
}: SkeletonBlocksProps) => {
  return (
    <div
      className={`${SkeletonStyles.Wrapper} ${
        addPadding ? SkeletonStyles.AddMargin : ''
      } ${layoutSpacing ? SkeletonStyles.Layout : ''}`}
      style={{ '--order': order } as React.CSSProperties}
    >
      {Array.from(Array(blocksNumber).keys()).map(i => (
        <div className={SkeletonStyles.Block} key={i}>
          <div className={SkeletonStyles.Image}></div>
        </div>
      ))}
    </div>
  )
}

export default SkeletonBlocks
