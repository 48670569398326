import React, { ReactNode } from 'react'
import * as ContentStyles from './Content.module.scss'
import Hr from '../Hr/Hr'

interface ContentProps {
  children: ReactNode
  alignCenter?: boolean
}

const Content = ({ children, alignCenter }: ContentProps) => {
  return (
    <div
      className={`${ContentStyles.Wrapper} ${
        alignCenter ? ContentStyles.AlignCenter : ''
      }`}
    >
      <div className={ContentStyles.Inner}>{children}</div>
      <Hr />
    </div>
  )
}

export default Content
